import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import RuledBreak from "../../components/RuledBreak"
import { Accordion, AccordionItem as Item } from "../../components/Accordion"

const Faqs = ({ data }) => {
  const headerImg = data.header.edges[0].node.childImageSharp.fluid
  return (
    <Layout
      fluid={headerImg}
      alt={"Cali Reeves helping to answer your questions"}
      height={400}
      mobile={300}
    >
      <SEO
        title="FAQs"
        keywords={[
          "Eyelashes",
          "Cali Reeves",
          "FAQs",
          "Frequently Asked Questions",
        ]}
      />
      <div className="row mt-5">
        <div className="col-md-12">
          <RuledBreak>FAQs</RuledBreak>
        </div>
      </div>
      <Accordion>
        <Item
          title={`What is videography?`}
          content={[`The process or art of making video films`]}
        />
        <Item
          title={`What is photography?`}
          content={[
            `The art or practice of taking and processing photographs.`,
          ]}
        />
        <Item
          title={`What are volume eyelash extensions?`}
          content={[
            `“Volume lashes are a newer and more innovative technique where multiple extensions are attached to one lash to give more dimension and texture. They provide a fuller look and require more skill.” - Pop Sugar`,
          ]}
        />
        <Item
          title={`Will I receive a manual?`}
          content={[
            `Bring a notepad and pen(cil) or your smartphone to take notes. Physical manuals will NOT be provided during these trainings.`,
            `This applies to both the group and private 2-Day Fan Like a Pro trainings.`,
          ]}
        />
        <Item
          title={`How many students in a group training?`}
          content={[
            `Six (6) students maximum. Private training sessions are also available for sign-up in the EDUCATION section.`,
          ]}
        />
        <Item
          title={`Will I receive a kit with the in-person trainings?`}
          content={[
            `Yes, below is a list of what is provided in your kit:`,
            `2 VOLUME TWEEZERS`,
            `LASH TILE`,
            `LASH ADHESIVE`,
            `3 DIFFERENT TAPES`,
            `2 LASH TRAYS OF YOUR CHOICE (M, L+, L, CC, or D) - make selection when registering`,
            `ATTACHABLE SMARTPHONE CAMERA LENSES`,
            `STYLUS`,
            `PRO-MADE LASH TRAY`,
          ]}
        />
        <Item
          title={`Is there a deposit in order to reserve my spot?`}
          content={[
            `Yes. A non-refundable deposit of $500 USD minimum is required to reserve your seat in a Fan Like a Pro class.`,
            `This applies to both the group and private 2-Day Fan Like a Pro trainings.`,
          ]}
        />
        <Item
          title={`When is the full amount due trainings?`}
          content={[
            `After putting down a $500 minimum non-refundable deposit, the remainder of the total amount is due 14 days prior to the training date. `,
            `This applies to both the group and private 2-Day Fan Like a Pro trainings.`,
          ]}
        />
        <Item
          title={`Will I need a lash model?`}
          content={[
            `No. You will not be needing a lash model. This course focuses on the creation of volume fans, photography, and videography. Not the application of volume lashes. `,
            `This applies to both the group and private 2-Day Fan Like a Pro trainings.`,
          ]}
        />
      </Accordion>
    </Layout>
  )
}

export const query = graphql`
  query faqstQuery {
    header: allFile(filter: { name: { eq: "faq_header_1201x955" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`
export default Faqs
