import React, { useState } from "react"
import Styled, { keyframes } from "styled-components"

import { Plus } from "../Icons"

const Item = Styled.div`
    border: 1px solid #e5e5e5;
    border-left: 0;
    border-right: 0;
`
const Title = Styled.div`
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 10px 20px 10px;
    svg {
        flex-shrink: 0;
        width: 1.5rem;
        color: ${({ open }) => (open ? "red" : "#000000")};
        transition: all 0.4s cubic-bezier(0.25, 0.25, 0.25, 0.25);
        transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0deg)")};;
        @media (max-width: 767px) {
            width: 1.3rem;
        }
    }
    h1 {
        font-size: 2rem;
        color: #000000;
        padding-left: 10px;
        @media (max-width: 767px) {
            font-size: 1.5rem;
        }
    }
`
const ShowContent = keyframes`
    from {
        padding: 0;
        opacity: 0;
    }
    to {
        padding: 20px 10px 20px 10px;
        opacity: 1;
    }
`
const Content = Styled.div`
    border-top: 1px solid #e5e5e5;
    opacity: ${({ open }) => (open ? `1` : `0`)};
    display: ${({ open }) => (open ? `block` : `none`)};
    visibility: ${({ open }) => (open ? `visible` : `hidden`)};
    pointer-events: ${({ open }) => (open ? `all` : `none`)};
    animation: ${ShowContent} 1000ms ease-in-out both;
    padding: ${({ open }) => (open ? `20px 10px 20px 10px` : `0`)};
    p {
        padding-top: 10px;
        display: list-item;
        list-style-type: disc;
        list-style-position: inside;
        color: #000000;
    }
`

// Accordion Container
const Accordion = ({ children }) => <div className="row my-4">{children}</div>
// Each Accoridion Item
const AccordionItem = props => {
  const [open, toggleOpen] = useState(false)

  const handleOnclick = () => {
    toggleOpen(!open)
  }
  return (
    <div className="col-md-12">
      <Item>
        <Title onClick={handleOnclick} open={open}>
          <Plus />
          <h1>{props.title}</h1>
        </Title>
        <Content open={open}>
          {props.content.map(item => {
            return <p key={item}>{item}</p>
          })}
        </Content>
      </Item>
    </div>
  )
}

export { Accordion, AccordionItem }
